import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ComponentLinks, FigmaEmbed } from '../common';
import { YearPicker } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { useState, useMemo } from 'react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "yearpicker"
    }}>{`YearPicker`}</h1>
    <ComponentLinks name={'YearPicker'} storybook={'/?path=/story/calendar-calendar--year-picker'} vueStorybook={'/?path=/story/calendar-yearpicker--playground'} figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=30243%3A43619'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`YearPicker – компонент для выбора года из списка доступных опций.`}</p>
    <p>{`YearPicker контролируемый компонент: для его работы следует передать пропы `}<inlineCode parentName="p">{`value`}</inlineCode>{` и `}<inlineCode parentName="p">{`onChange`}</inlineCode>{`.`}</p>
    <Playground __position={1} __code={'() => {\n  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())\n  return (\n    <div\n      style={{\n        height: 150,\n        display: \'flex\',\n        justifyContent: \'center\',\n      }}\n    >\n      <YearPicker\n        from={1920}\n        to={new Date().getFullYear()}\n        value={currentYear}\n        onChange={setCurrentYear}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      ComponentLinks,
      FigmaEmbed,
      YearPicker,
      Playground,
      Props,
      useState,
      useMemo,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
        return <div style={{
          height: 150,
          display: 'flex',
          justifyContent: 'center'
        }}>
            <YearPicker from={1920} to={new Date().getFullYear()} value={currentYear} onChange={setCurrentYear} mdxType="YearPicker" />
       </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "отключенные-годы"
    }}>{`Отключенные годы`}</h3>
    <p>{`Определенные годы можно отключить, передав проп `}<inlineCode parentName="p">{`disabledYears`}</inlineCode>{`.`}</p>
    <Playground __position={2} __code={'() => {\n  const disabledYears = useMemo(() => [1921, 1922], [])\n  const [currentYear, setCurrentYear] = useState(2022)\n  return (\n    <div\n      style={{\n        height: 150,\n        display: \'flex\',\n        justifyContent: \'center\',\n      }}\n    >\n      <YearPicker\n        disabledYears={disabledYears}\n        from={1920}\n        to={new Date().getFullYear()}\n        value={currentYear}\n        onChange={setCurrentYear}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      ComponentLinks,
      FigmaEmbed,
      YearPicker,
      Playground,
      Props,
      useState,
      useMemo,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const disabledYears = useMemo(() => [1921, 1922], []);
        const [currentYear, setCurrentYear] = useState(2022);
        return <div style={{
          height: 150,
          display: 'flex',
          justifyContent: 'center'
        }}>
            <YearPicker disabledYears={disabledYears} from={1920} to={new Date().getFullYear()} value={currentYear} onChange={setCurrentYear} mdxType="YearPicker" />
       </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={YearPicker} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      